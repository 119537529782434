<template>
  <div class="content">
    <moreHeader :iconColor="1"></moreHeader>
    <h1 class="datahi">{{ data.title }}</h1>
    <p v-if="data.data">
      {{ data.data }}
    </p>
    <div v-if="data.content">
      <div v-for="(item, i) in data.content" :key="i">
        <div class="cloudTitle">
          <van-icon name="stop" class="titleIcon" /> {{ item.title }}
        </div>
        <p>{{ item.text }}</p>
      </div>
    </div>
    <div class="appTitle" v-if="data.text1">整合城市各行业移动应用服务</div>
    <p v-if="data.text">{{ data.text }}</p>
    <p v-if="data.text1">{{ data.text1 }}</p>
    <img v-if="data.img" :src="data.img" alt="" />
    <div v-if="data.imgdata" class="safetyCity">
      <span v-for="(item, i) in data.imgdata" :key="i">
        <img :src="item.img" alt="" />
        <span>{{ item.text }}</span>
      </span>
    </div>
    <div class="more">更多场景：</div>

    <div v-if="id != 0" class="moreScene" @click="go(0)">数据中心</div>
    <div v-if="id != 1" class="moreScene" @click="go(1)">
      时空大数据与云平台
    </div>
    <div v-if="id != 2" class="moreScene" @click="go(2)">运行指挥中心</div>
    <div v-if="id != 3" class="moreScene" @click="go(3)">智慧城市APP</div>
    <div v-if="id != 4" class="moreScene" @click="go(4)">平安城市</div>
  </div>
</template>

<script>
import moreHeader from "../../../components/header.vue";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      id: null,
      data: {},
      data1: {
        title: "智慧城市数据中心",
        text: "",
        img: require("../../../assets/assets/cityImg/img2.png"),
        data: "城市大数据中心、 国家A级计算机机房、国家三级顶保标准（Tier3+）、 超大机房、 快速部署、 高设备利用率、数据完整备份低风险、电信级别托管，一站式运维、集中托管到城市大数据中心平台，低能耗",
      },
      data2: {
        title: "时空大数据与云平台",
        img: require("../../../assets/assets/cityImg/img3.png"),
        content: [
          {
            title: "高效的海量数据自动化实景三维建模",
            text: "通过超算中心及自主核心知识产权软件打造自主可控、高效优质的全空间三维建模一体化解决方案",
          },
          {
            title: "多资源数据的融合与重构",
            text: "实现卫星影像、低空无人机、地面激光点云、行业专题数据等多源数据的融合与重构",
          },
          {
            title: "全域空间的一张图展示",
            text: "室内室外、地上地下一体化，实现全域空间的一张图展示",
          },
          {
            title: "高精度、无缝位置服务",
            text: "融合北斗、组合惯导的室内外一体化定位导航，实现空间位置与高精度地图的融合",
          },
          {
            title: "新型产业驱动",
            text: "VR、AR等新型产业核心平台 数据银行：双向数据服务，用户本身也可称为生产人员，分享平台收益人工智能汇聚平台，基础应用工具+公众创新应用=众包人工智能服务",
          },
        ],
      },
      data3: {
        title: "智慧城市运行指挥中心",
        text: "整合各委办局的信息化系统,实现部门、区域之间的数据关联和共享,消除“信息孤岛” 对数据进行融合处理、深度挖掘、决策分析，是智慧城市的核心中枢。",
        img: require("../../../assets/assets/cityImg/img7.png"),
      },
      data4: {
        title: "智慧城市APP",
        text: "VR、AR等新型产业核心平台",
        text1:
          "数据银行：双向数据服务，用户本身也可称为生产人员，分享平台收益人工智能汇聚平台，基础应用工具+公众创新应用=众包人工智能服务",
        img: require("../../../assets/assets/cityImg/img8.png"),
      },
      data5: {
        title: "平安城市",
        imgdata: [
          {
            text: "治安监控前端",
            img: require("../../../assets/assets/cityImg/safetyImg1.png"),
          },
          {
            text: "乡村监控前端",
            img: require("../../../assets/assets/cityImg/safetyImg2.png"),
          },
          {
            text: "实景作战系统",
            img: require("../../../assets/assets/cityImg/safetyImg3.png"),
          },
          {
            text: "微卡口系统",
            img: require("../../../assets/assets/cityImg/safetyImg4.png"),
          },
          {
            text: "人脸识别系统",
            img: require("../../../assets/assets/cityImg/safetyImg5.png"),
          },
          {
            text: "综合视频监控共享平台",
            img: require("../../../assets/assets/cityImg/safetyImg6.png"),
          },
          {
            text: "公安治安综合管控平台",
            img: require("../../../assets/assets/cityImg/safetyImg7.png"),
          },
          {
            text: "公安指挥调度系统",
            img: require("../../../assets/assets/cityImg/safetyImg8.png"),
          },
        ],
      },
    };
  },
  created() {
    this.id = this.$route.query.cityId;
    const i = this.$route.query.cityId;
    // eslint-disable-next-line eqeqeq
    if (i == 0) {
      this.data = this.data1;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 1) {
      this.data = this.data2;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 2) {
      this.data = this.data3;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 3) {
      this.data = this.data4;
    }
    // eslint-disable-next-line eqeqeq
    if (i == 4) {
      this.data = this.data5;
    }
  },
  methods: {
    go(id) {
      this.$router.push({
        path: "/m_cityDetail",
        query: {
          cityId: id,
        },
      });
      this.id = id;
      if (id == 0) {
        this.data = this.data1;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 1) {
        this.data = this.data2;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 2) {
        this.data = this.data3;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 3) {
        this.data = this.data4;
      }
      // eslint-disable-next-line eqeqeq
      if (id == 4) {
        this.data = this.data5;
      }
    },
  },
};
</script>

<style scoped lang="less">
.content {
  background-color: #fff;
  box-sizing: border-box;
  margin-top: -27px;
  box-sizing: border-box;
  padding-bottom: 10px;
  > h1 {
    // padding-left: 10px;
    margin: 0;
    font-size: 20px;
    font-weight: normal;
    color: #333333;
    padding: 70px 10px 30px;
  }
  P {
    padding-left: 10px;
    text-indent: 2em;
    width: calc(100% - 20px);
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 28px;
    color: #666666;
    margin: 0px 0 20px;
  }
  > img {
    padding-left: 10px;
    display: inline-block;
    width: calc(100% - 20px);
    margin-bottom: 10px;
  }
  .more {
    margin: 0 20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-top: 10px;
    border-top: 1px solid #d5d5d5;
    padding-top: 20px;
  }
  .moreScene {
    padding-left: 20px;
    width: 260px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #365ef1;
    margin-top: 10px;
  }
  .appTitle {
    height: 22px;
    font-size: 16px;
    font-weight: normal;
    color: #333333;
    padding: 0px 0 0 10px;
  }

  .cloudTitle {
    font-size: 18px;
    color: #1864e3;
    margin-left: 10px;
    // vertical-align: middle;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
    .titleIcon {
      margin-top: -5px;
      transform: translateX(-0%) translateY(20%) rotate(45deg);
    }
  }
  .safetyCity {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: max-content;
    > span {
      display: inline-block;
      width: calc(50% - 15px);
      height: max-content;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        // margin-left: 20px;
      }
      span {
        width: 100%;
        display: inline-block;
        // height: 18px;
        // line-height: 18px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        padding: 10px 0 10px;
      }
    }
  }
}
</style>
